import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21c89e2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "monitorlist" }
const _hoisted_2 = { style: {"height":"100%"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "monitor-name" }
const _hoisted_5 = { class: "monitor-status" }
const _hoisted_6 = { class: "status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, {
      title: _ctx.$route.meta.title,
      "left-arrow": "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
      placeholder: "",
      fixed: ""
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_van_pull_refresh, {
          modelValue: _ctx.refreshing,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.refreshing) = $event)),
          onRefresh: _ctx.onRefresh
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_list, {
              loading: _ctx.loading,
              "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loading) = $event)),
              finished: _ctx.finished,
              "finished-text": "没有更多了",
              onLoad: _ctx.onLoad
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monitorList, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "monitor-row",
                    key: index,
                    onClick: ($event: any) => (
              _ctx.$router.push({
                name: 'Monitorplay',
                query: { id: item.id, name: item.name, way: item.way, channel: item.channel },
              })
            )
                  }, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(item.name), 1),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["dot", { _close: !_ctx.runningList.includes(item.id) }])
                      }, null, 2),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.runningList.includes(item.id) ? "正常" : "离线"), 1)
                    ])
                  ], 8, _hoisted_3))
                }), 128))
              ]),
              _: 1
            }, 8, ["loading", "finished", "onLoad"])
          ]),
          _: 1
        }, 8, ["modelValue", "onRefresh"])
      ])
    ])
  ], 64))
}